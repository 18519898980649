import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import './Footer.scss';

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            contact: null
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        // Récupération des coordonnées de contact
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/settings/contact'
        }).then((response) => {
            if (response.data) {

                this.setState({
                    contact: response.data
                });
            }
        });
    }

    render() {
        return (
            <div className="Footer">
                <img src="/logo/logo_simple.svg" alt="logo simple du Refuge des Chamois" className="logo-simple" />
                <div>
                    <p>Au Refuge des Chamois - 47B Les Trexons, 88430 Gerbépal</p>
                </div>
                {
                    this.state.contact && (this.state.contact.phone || this.state.contact.email)
                    && <div>
                        <p>
                            {
                                this.state.contact.phone
                                && <a href={"tel:" + this.state.contact.phone} target="_blank" rel="noreferrer" title="Téléphonez nous" className="legal-notice">{formatPhoneNumberIntl(this.state.contact.phone)}</a> 
                            }
                            {
                                this.state.contact.phone && this.state.contact.email
                                && <> | </>
                            }
                            {
                                this.state.contact.email
                                && <a href={"mailto:" + this.state.contact.email} target="_blank" rel="noreferrer" title="Envoyez nous un mail" className="legal-notice">{this.state.contact.email}</a>
                            }
                        </p>
                    </div>
                }
                {
                   this.state.contact && (this.state.contact.facebook_url || this.state.contact.messenger_url)
                   && <div className="social-links">
                       <p>
                        {
                            this.state.contact.facebook_url
                            && <a href={this.state.contact.facebook_url} target="_blank" rel="noreferrer" title="Page Facebook" className="legal-notice"><i className="fa-brands fa-facebook fa-2x"></i></a> 
                        }
                        {
                            this.state.contact.messenger_url
                            && <a href={this.state.contact.messenger_url} target="_blank" rel="noreferrer" title="Envoyez nous un message sur Messenger" className="legal-notice"><i className="fa-brands fa-facebook-messenger fa-2x"></i></a>
                        }
                    </p>
                   </div>
                }
                <div>
                    <p>Au Refuge des Chamois © 2022 | <Link to="/mentions-legales" className="legal-notice">Mentions légales</Link> | <Link to="/cg-vente" className="legal-notice">Conditions générales de vente</Link></p>
                </div>
                <div>
                    <p>Site web réalisé par <a href="https://nicolas-carbonnier.github.io" target="_blank" rel="noreferrer" title="Portfolio Nicolas Carbonnier" className="nicolas-carbonnier">Nicolas<span className="bold">Carbonnier</span></a></p>
                </div>
            </div>
        );
    }
}

export default Footer;