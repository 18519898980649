import React from 'react';
import Parser from 'html-react-parser';
import Icon from '@mui/material/Icon';

import './ReviewBlock.scss';
import FormatManager from '../../../../utils/FormatManager';

class ReviewBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            r: this.props.review.review ? Parser(this.props.review.review).filter((item) => (
                item !== '\n'
            )) : null,
            c: this.props.review.comment && this.props.review.comment.comment ? Parser(this.props.review.comment.comment).filter((item) => (
                item !== '\n'
            )) : null,
            show_more_review: false,
            show_more_review_button: true,
            show_more_comment: false,
            show_more_comment_button: true
        };
    }

    componentDidMount() {
        if (this.state.r && this.state.r.length <= this.props.limit + 1) this.setState({ 
            show_more_review: true,
            show_more_review_button: false 
        });
        if (this.state.c && this.state.c.length <= this.props.limit + 1) this.setState({ 
            show_more_comment: true,
            show_more_comment_button: false 
        });
    }

    onShowMoreReviewClick = () => {
        this.setState({ show_more_review: !this.state.show_more_review });
    }

    onShowMoreCommentClick = () => {
        this.setState({ show_more_comment: !this.state.show_more_comment });
    }

    render() {
        const review = this.props.review;

        return (
            <div className="ReviewBlock">
                <div className="review-content">
                    <h4>{review.firstname}</h4>
                    <div className="date">{FormatManager.dateStringFormat(review.date)}</div>
                    <div className="note">
                        {
                            review.note >= 1 
                                ? <Icon fontSize="small">star_rate</Icon>
                                : review.note === 0.5
                                    ? <Icon fontSize="small">star_half</Icon>
                                    : <Icon fontSize="small">star_border</Icon>
                        }
                        {
                            review.note >= 2
                                ? <Icon fontSize="small">star_rate</Icon>
                                : review.note === 1.5
                                    ? <Icon fontSize="small">star_half</Icon>
                                    : <Icon fontSize="small">star_border</Icon>
                        }
                        {
                            review.note >= 3 
                                ? <Icon fontSize="small">star_rate</Icon>
                                : review.note === 2.5
                                    ? <Icon fontSize="small">star_half</Icon>
                                    : <Icon fontSize="small">star_border</Icon>
                        }
                        {
                            review.note >= 4
                                ? <Icon fontSize="small">star_rate</Icon>
                                : review.note === 3.5
                                    ? <Icon fontSize="small">star_half</Icon>
                                    : <Icon fontSize="small">star_border</Icon>
                        }
                        {
                            review.note >= 5
                                ? <Icon fontSize="small">star_rate</Icon>
                                : review.note === 4.5
                                    ? <Icon fontSize="small">star_half</Icon>
                                    : <Icon fontSize="small">star_border</Icon>
                        }
                    </div>
                    {
                        this.state.r
                        && <div className="review">
                            <div className="text">
                                {
                                    (
                                        !this.state.show_more_review 
                                        && <> 
                                            {this.state.r.slice(0, this.props.limit)}
                                            <span className="three-dots">...</span>
                                        </>
                                    )
                                    || this.state.r
                                }
                                {
                                    this.state.show_more_review_button
                                    && <button type="button" className="show-more-button" onClick={this.onShowMoreReviewClick}>
                                        {this.state.show_more_review ? "Afficher moins" : "Afficher plus"}
                                    </button>
                                }
                            </div>
                        </div>
                    }
                </div>
                {
                    this.state.c
                    && <div className="comment-content">
                        <h4>Réponse des propriétaires</h4>
                        <div className="date">{FormatManager.dateStringFormat(review.comment.date)}</div>
                        <div className="comment">
                            <div className="text">
                                {
                                    (
                                        !this.state.show_more_comment 
                                        && <>
                                            {this.state.c.slice(0, this.props.limit)}
                                            <span className="three-dots">...</span>
                                        </>
                                    )
                                    || this.state.c
                                }
                                {
                                    this.state.show_more_comment_button
                                    && <button type="button" className="show-more-button" onClick={this.onShowMoreCommentClick}>
                                        {this.state.show_more_comment ? "Afficher moins" : "Afficher plus"}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default ReviewBlock;