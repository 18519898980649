import React from 'react';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Icon from '@mui/material/Icon';
import Parser from 'html-react-parser';
import { animateScroll } from 'react-scroll';

import FormatManager from '../../../utils/FormatManager';
import TEXT_POSITION from '../../../constants/text_position.enum';
import './Slideshow.scss';

class Slideshow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            slideshow: null,
            hidden: true,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/slideshow'
        }).then((response) => {
            if (response.data) {

                this.setState({
                    slideshow: response.data
                });
                setTimeout(() => this.setState({ hidden: false }), 1);
            }
        });
    }

    getSlideClassName = (slide) => {
        if (slide.text && slide.text.replace(/<[^>]*>?/gm, '').trim() !== '') {
            switch (slide.text_position) {
                case TEXT_POSITION.TOP_LEFT:
                    return 'slide-image top-left';
                case TEXT_POSITION.TOP_RIGHT:
                    return 'slide-image top-right';
                case TEXT_POSITION.BOTTOM_RIGHT:
                    return 'slide-image bottom-right';
                case TEXT_POSITION.BOTTOM_LEFT:
                    return 'slide-image bottom-left';
                default:
                    return 'slide-image';
            }
        }
        return 'slide-image';
    }

    onKnowMoreClick = () => {
        animateScroll.scrollTo(window.innerHeight - (
            window.innerWidth > 1279 ? 90
                : window.innerWidth > 499 ? 77
                    : 67
        ), {
            duration: 800,
            smooth: true
        });
    }

    render() {
        return (
            <div className="Slideshow" style={{ 
                height: Math.min(document.documentElement.clientHeight, window.innerHeight || 0)
            }}>
                {
                    this.state.slideshow && this.state.slideshow.length > 0
                    && <>
                        <Carousel
                            autoPlay={true}
                            interval="6000"
                            transitionTime="800"
                            infiniteLoop={true}
                            axis="horizontal"
                            stopOnHover={false}
                            showStatus={false}
                            showThumbs={false}
                            showIndicators={false}
                            // swipeScrollTolerance={1}
                            preventMovementUntilSwipeScrollTolerance={true}
                            renderArrowPrev={(onClickHandler, hasPrev, label) => (
                                <button type="button" className="control-arrow control-prev" onClick={onClickHandler} title="Image précédente" aria-label={label}>
                                    <Icon fontSize="large">navigate_before</Icon>
                                </button>
                            )}
                            renderArrowNext={(onClickHandler, hasNext, label) => (
                                <button type="button" className="control-arrow control-next" onClick={onClickHandler} title="Image suivante" aria-label={label}>
                                    <Icon fontSize="large">navigate_next</Icon>
                                </button>
                            )}
                        >
                            {
                                this.state.slideshow.map((slide, index) => (
                                    <div className={this.getSlideClassName(slide)} key={index}>
                                        <img src={slide.image.paths.w1920}
                                            srcSet={FormatManager.imageSrcSet(slide.image.paths)}
                                            alt={slide.image.name}
                                            className={this.state.hidden ? "hidden" : ""}
                                        />
                                        <div className={this.state.hidden ? "legend hidden" : "legend"}>
                                            {slide.text && Parser(slide.text)}
                                        </div>
                                    </div>
                                ))
                            }
                        </Carousel>
                        <button type="button" onClick={this.onKnowMoreClick} className="know-more">
                            <Icon fontSize="large">arrow_downward</Icon>
                        </button>
                    </>
                }
            </div>
        );
    }

}

export default Slideshow;