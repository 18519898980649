import { parse, isDate } from 'date-fns';
import moment from 'moment';
import { convertToRaw, convertFromHTML, ContentState, EditorState, CompositeDecorator } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import Link from './editor_field/Link';

class FormatManager {

    static datesArray = (dates) => {
        if (dates) return dates.map((date) => new Date(date));
        return null;
    }

    static dateStringFormat = (date) => {
        return new Intl.DateTimeFormat('fr-FR', {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric"
        }).format(new Date(date));
    }

    static parseDateString = (value, originalValue) => {
        const parsedDate = isDate(originalValue)
            ? originalValue
            : parse(originalValue, 'dd/MM/yyyy', new Date());
        
        return parsedDate;
    }

    static dateDBFormat = (date) => {
        return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    static dateLocaleFormat = (date) => {
        return new Date(date).toLocaleDateString();
    }

    static dateTimeLocaleFormat = (date) => {
        date = new Date(date);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    }

    static timeLocaleFormat = (date) => {
        return new Intl.DateTimeFormat('fr-FR', {
            hour: "numeric",
            minute: "numeric"
        }).format(new Date(date));
    }

    static priceFormat = (price) => {
        return new Intl.NumberFormat('fr-FR', {
            style: "currency",
            currency: "EUR"
        }).format(price);
    }

    static imageSrcSet = (paths) => {
        return process.env.REACT_APP_API_URL + paths.w320 + " 320w, "
            + process.env.REACT_APP_API_URL + paths.w640 + " 640w, "
            + process.env.REACT_APP_API_URL + paths.w768 + " 768w, "
            + process.env.REACT_APP_API_URL + paths.w1024 + " 1024w, "
            + process.env.REACT_APP_API_URL + paths.w1366 + " 1366w, "
            + process.env.REACT_APP_API_URL + paths.w1600 + " 1600w, "
            + process.env.REACT_APP_API_URL + paths.w1920 + " 1920w";
    }

    static editorToHtml = (editorState) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const linkTransform = (entity, text) => {
            if (entity.type !== 'LINK') return;
            return `<a href="${entity.data.url}" target="_blank">${text}</a>`;
        };
        return draftToHtml(rawContentState, {}, false, linkTransform);
    }

    static htmlToEditor = (html = null) => {
        const decorator = new CompositeDecorator([
            {
                strategy: Link.findLinkEntities,
                component: Link.LinkEntity,
            }
        ]);

        if (html) {
            const blocksFromHtml = convertFromHTML(html);
            const state = ContentState.createFromBlockArray(
                blocksFromHtml.contentBlocks,
                blocksFromHtml.entityMap
            );
            return EditorState.createWithContent(state, decorator);
        }
        return EditorState.createEmpty(decorator);
    }
}

export default FormatManager;