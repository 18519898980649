import React from 'react';
import { useField, useFormikContext } from 'formik';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import 'moment/locale/fr';

import './DatePickerField.scss';

const DatePickerField = React.forwardRef((props, ref ) => {
    const { setFieldValue, setFieldTouched } = useFormikContext();
    const [field] = useField(props);
    return (
        <DayPickerInput
            {...field}
            {...props}
            ref={ref}
            placeholder={null}
            format="DD/MM/yyyy"
            formatDate={formatDate}
            parseDate={parseDate}
            hideOnDayClick={false}
            dayPickerProps={{
                ...props.dayPickerProps,
                showOutsideDays: true,
                localeUtils: MomentLocaleUtils,
                locale: 'fr'
            }}
            onDayChange={async (val) => {
                if (props.onDayChange) props.onDayChange(val, setFieldValue);
                setFieldValue(field.name, val);
                await Promise.resolve();
                setFieldTouched(field.name, true);
            }}
        />
    );
});

export default DatePickerField;