import React from 'react';
import { animateScroll } from 'react-scroll';
import Icon from '@mui/material/Icon';

import './ToTop.scss';

class ToTop extends React.Component {
    onToTopClick = () => {
        animateScroll.scrollTo(0, {
            duration: 800,
            smooth: true
        });
    }

    render() {
        return (
            <button type="button" className={this.props.scrolled ? "ToTop" : "ToTop hidden"} onClick={this.onToTopClick}>
                <Icon fontSize="medium">arrow_upward</Icon>
            </button>
        );
    }
}

export default ToTop;