import React from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import Icon from '@mui/material/Icon';

import './EditorField.scss';

class EditorField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show_url_input: false,
            url: null
        };

        this.editorRef = React.createRef();
        this.urlRef = React.createRef();
    }

    onChange = (editorState) => {
        if (this.props.onChange) this.props.onChange('editorState', editorState);
    }

    focus = () => {
        this.editorRef.current.focus();
    }

    onURLChange = (e) => {
        this.setState({url: e.target.value})
    }

    handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(this.props.editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }
  
    toggleBlockType = (blockType) => {
        this.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType));
    }

    toggleInlineStyle = (inlineStyle) => {
        this.onChange(RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle));
    }

    promptForLink = (e) =>{
        e.preventDefault();
        const selection = this.props.editorState.getSelection();
        if (!this.state.show_url_input) {
            if (!selection.isCollapsed()) {
                const contentState = this.props.editorState.getCurrentContent();
                const startKey = this.props.editorState.getSelection().getStartKey();
                const startOffset = this.props.editorState.getSelection().getStartOffset();
                const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
                const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
                let url = '';
                if (linkKey) {
                    const linkInstance = contentState.getEntity(linkKey);
                    url = linkInstance.getData().url;
                }
                this.setState({
                    show_url_input: true,
                    url: url
                }, () => {
                    setTimeout(() => this.urlRef.current.focus(), 0);
                });
            }
        } else {
            this.setState({
                show_url_input: false,
                url: null
            }, () => {
                setTimeout(() => this.editorRef.current.focus(), 0);
            });
        }
    }
    
    confirmLink = (e) => {
        e.preventDefault();
        const contentState = this.props.editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            { url: this.state.url }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        
        let nextEditorState = EditorState.set(this.props.editorState, 
            { currentContent: contentStateWithEntity }
        );
    
        nextEditorState = RichUtils.toggleLink(nextEditorState, nextEditorState.getSelection(), entityKey);
    
        this.props.onEditorStateChange(nextEditorState);
        this.setState({
            show_url_input: false,
            url: null,
        }, () => {
            setTimeout(() => this.editorRef.current.focus(), 0);
        });
    }

    onLinkInputKeyDown = (e) => {
        if (e.which === 13) this.confirmLink(e);
    }

    removeLink = (e) => {
        e.preventDefault();
        const selection = this.props.editorState.getSelection();
        if (!selection.isCollapsed()) this.props.onEditorStateChange(RichUtils.toggleLink(this.props.editorState, selection, null));
    }

    render() {
        let className = 'EditorField-editor';
        var contentState = this.props.editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' EditorField-hidePlaceholder';
            }
        }
    
        return (
            <div className={this.props.readOnly ? "EditorField read-only" : "EditorField"}>
                {
                    (
                        !this.props.readOnly 
                        && !(this.props.disabledBlock 
                            && this.props.disabledBlock.length > 0 
                            && this.props.disabledBlock[0] === 'all'
                            && this.props.disabledInline
                            && this.props.disabledInline.length > 0
                            && this.props.disabledInline[0] === 'all'
                        )
                    )
                    && <div className="EditorField-tools">
                        {
                            (!this.props.disabledBlock || this.props.disabledBlock.length === 0 || this.props.disabledBlock[0] !== 'all')
                            && <BlockStyleControls
                                disabledBlock={this.props.disabledBlock}
                                editorState={this.props.editorState}
                                onToggle={this.toggleBlockType}
                            />
                        }
                        {
                            (!this.props.disabledInline || this.props.disabledInline.length === 0 || this.props.disabledInline[0] !== 'all')
                            && <InlineStyleControls
                                disabledInline={this.props.disabledInline}
                                editorState={this.props.editorState}
                                onToggle={this.toggleInlineStyle}
                                showUrlInput={this.state.show_url_input}
                                promptForLink={this.promptForLink}
                                onURLChange={this.onURLChange}
                                urlRef={this.urlRef}
                                url={this.state.url}
                                onLinkInputKeyDown={this.onLinkInputKeyDown}
                                confirmLink={this.confirmLink}
                                removeLink={this.removeLink}
                            />
                        }
                    </div>
                }
                <div className={className} onClick={this.focus}>
                    <Editor
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={this.props.editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        onChange={this.onChange}
                        readOnly={this.props.readOnly}
                        placeholder="Saisissez votre texte..."
                        ref={this.editorRef}
                        spellCheck={true}
                    />
                </div>
            </div>
        );
    }
}

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote':
            return 'EditorField-blockquote';
        default:
            return null;
    }
}

class StyleButton extends React.Component {
    constructor() {
        super();
        
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'EditorField-styleButton';
        if (this.props.active) className += ' EditorField-activeButton';
    
        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.label}
            </span>
        );
    }
}

const BLOCK_TYPES = [
    { label: <span className="EditorField-icon"><Icon fontSize="small">title</Icon><span>1</span></span>, style: 'header-one' },
    { label: <span className="EditorField-icon"><Icon fontSize="small">title</Icon><span>2</span></span>, style: 'header-two' },
    { label: <span className="EditorField-icon"><Icon fontSize="small">title</Icon><span>3</span></span>, style: 'header-three' },
    { label: <span className="EditorField-icon"><Icon fontSize="small">title</Icon><span>4</span></span>, style: 'header-four' },
    { label: <span className="EditorField-icon"><Icon fontSize="small">title</Icon><span>5</span></span>, style: 'header-five' },
    { label: <span className="EditorField-icon"><Icon fontSize="small">title</Icon><span>6</span></span>, style: 'header-six' },
    { label: <span className="EditorField-icon"><Icon fontSize="small">format_list_bulleted</Icon></span>, style: 'unordered-list-item' },
    { label: <span className="EditorField-icon"><Icon fontSize="small">format_list_numbered</Icon></span>, style: 'ordered-list-item' }
];

const BlockStyleControls = (props) => {
    const selection = props.editorState.getSelection();
    const blockType = props.editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
  
    return (
        <div className="EditorField-controls">
            {BLOCK_TYPES.map(type =>
                (!props.disabledBlock || !props.disabledBlock.includes(type.style))
                && <StyleButton
                    key={type.style}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
}

var INLINE_STYLES = [
    { label: <span className="EditorField-icon"><Icon fontSize="small">format_bold</Icon></span>, style: 'BOLD' },
    { label: <span className="EditorField-icon"><Icon fontSize="small">format_italic</Icon></span>, style: 'ITALIC' },
    { label: <span className="EditorField-icon"><Icon fontSize="small">format_underlined</Icon></span>, style: 'UNDERLINE' }
];

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();

    let className = 'EditorField-styleButton';
    if (props.showUrlInput) className += ' EditorField-activeButton';

    return (
        <div className="EditorField-controls">
            {INLINE_STYLES.map((type) =>
                (!props.disabledInline || !props.disabledInline.includes(type.style))
                && <StyleButton
                    key={type.style}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
            {
                (!props.disabledInline || !props.disabledInline.includes('link'))
                && <>
                    <span className={className} onMouseDown={props.promptForLink}>
                        <span className="EditorField-icon"><Icon fontSize="small">add_link</Icon></span>
                    </span>
                    {
                        props.showUrlInput
                        && <span className="EditorField-urlInput">
                            <input 
                                onChange={props.onURLChange}
                                ref={props.urlRef}
                                type="text"
                                value={props.url}
                                onKeyDown={props.onLinkInputKeyDown}
                            />
                            <button onMouseDown={props.confirmLink}>
                                <span className="EditorField-icon"><Icon fontSize="small">add</Icon></span>
                            </button>
                        </span>
                    }
                    <span className={"EditorField-styleButton"} onMouseDown={props.removeLink}>
                        <span className="EditorField-icon"><Icon fontSize="small">link_off</Icon></span>
                    </span>
                </>
            }
        </div>
    );
}

export default EditorField;