import React from 'react';
import axios from 'axios';
import { animateScroll } from 'react-scroll';
import Parser from 'html-react-parser';
import LazyLoad from 'react-lazyload';

import FullscreenSlideshow from './fullscreen_slideshow/FullscreenSlideshow';
import FormatManager from '../../utils/FormatManager';
import IMAGES_POSITION from '../../constants/images_position.enum';
import PAGES from '../../constants/pages.enum';
import './Page.scss';

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            page: null,
            images: null,
            show_slideshow: false,
            image_index: null
        }
    }

    componentDidMount() {
        this.props.onFullscreenChange(false);
        this.loadData();
        animateScroll.scrollTo(0, {
            duration: 0
        });
        setTimeout(() => this.setState({ hidden: false }), 0);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.page !== this.props.page) {
            this.setState({ 
                hidden: true,
                page: null,
                images: null,
                show_slideshow: false,
                image_index: null
            }, () => {
                this.props.onFullscreenChange(false);
                this.loadData();
                animateScroll.scrollTo(0, {
                    duration: 0
                });
                setTimeout(() => this.setState({ hidden: false }), 0);
            });
        }
    }

    loadData = () => {
        // Récupération des données de la page
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/pages/by-page/' + this.props.page
        }).then((response) => {
            if (response.data) {
                this.setState({
                    page: response.data,
                    images: this.getImages(response.data)
                });
            }
        });
    }

    getImages = (data) => {
        let images = [];
        if (data.sections) data.sections.forEach(section => {
            if (section.images) section.images.forEach(image => {
                images.push(image);
            });
        });
        return images;
    }

    getImageIndex = (image) => {
        if (this.state.images && this.state.images.length > 0) return this.state.images.map((image) => (image._id)).indexOf(image._id);
        return -1;
    }

    getSectionClassName = (images_position, images) => {
        if (!images || images.length === 0) return 'section';
        switch(images_position) {
            case IMAGES_POSITION.LEFT:
                return 'section images-left';
            case IMAGES_POSITION.UNDER:
                return 'section images-under';
            case IMAGES_POSITION.RIGHT:
                return 'section images-right';
            default:
                return 'section'
        }
    }

    onImageClick = (e) => {
        if (e.target) this.setState({
            show_slideshow: true,
            image_index: parseInt(e.target.getAttribute('index'))
        });
        this.props.onFullscreenChange(true);
    }

    onSlideshowClose = () => {
        this.props.onFullscreenChange(false);
        setTimeout(() => this.setState({
            show_slideshow: false,
            image_index: null
        }), 400);
    }

    render() {
        return (
            <div className={
                this.props.page === PAGES.LEGAL_NOTICE || this.props.page === PAGES.TERMS_OF_SALES
                    ? this.state.hidden ? "Page notice hidden" : "Page notice"
                    : this.state.hidden ? "Page hidden" : "Page"
            }>
                <div className="header-image-container">
                    {
                        this.state.page && this.state.page.header_image
                        && <img
                            src={this.state.page.header_image.paths.w1920}
                            srcSet={FormatManager.imageSrcSet(this.state.page.header_image.paths)}
                            alt={this.state.page.header_image.name}
                            className="header-image"
                        />
                    }
                </div>
                <article className="content-container">
                    <h2>{this.state.page && this.state.page.title}</h2>
                    <div className="sections-container">
                        {
                            this.state.page && this.state.page.sections && this.state.page.sections.map((section, index) => (
                                <section className={this.getSectionClassName(section.images_position, section.images)} key={this.props.page + "-" + index}>
                                    <div className="text">{section.text && Parser(section.text)}</div>
                                    {
                                        section.images && section.images.length > 0
                                        && <div className="images-container">
                                            {section.images.map((image, i) => (
                                                <div className="image" key={this.props.page + "-" + index + "." + i}>
                                                    <LazyLoad offset={200} placeholder={<div className="lazyload-image"></div>} once>
                                                        <img
                                                            srcSet={FormatManager.imageSrcSet(image.paths)}
                                                            sizes={ 
                                                                "(max-width: 449px) 500px," +
                                                                "(max-width: 767px) 719px," +
                                                                "(max-width: 899px) 803px," +
                                                                "(max-width: 1279px) " + Math.round(15.4 * image.aspect_ratio * 10) / 10 + "rem, " +
                                                                Math.round(19.2 * image.aspect_ratio * 10) / 10 + "rem" 
                                                            }
                                                            src={image.paths.w1920}
                                                            alt={image.name}
                                                            index={this.getImageIndex(image)}
                                                            onClick={(e) => this.onImageClick(e)}
                                                        />
                                                    </LazyLoad>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </section>
                            ))
                        }
                    </div>
                </article>
                {
                    this.state.show_slideshow
                    && <FullscreenSlideshow
                        show_slideshow={this.state.show_slideshow}
                        images={this.state.images}
                        image_index={this.state.image_index}
                        onClose={this.onSlideshowClose}
                    />
                }
            </div>
        );
    }
}

export default Page;