import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { animateScroll } from 'react-scroll';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';
import { toast } from 'react-toastify';

import EditorField from '../../utils/editor_field/EditorField';
import FormatManager from '../../utils/FormatManager';
import './Review.scss';

class Review extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            exist: false,
            booking: null,
            error_exist: null,
            editor_state: FormatManager.htmlToEditor(),
            error_response: null,
            note_hover: 0,
            note_selected: false,
        }
    }

    componentDidMount() {
        this.props.onFullscreenChange(false);
        this.checkBooking();
        animateScroll.scrollTo(0, {
            duration: 0
        });
        setTimeout(() => this.setState({ hidden: false }), 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params && this.props.match.params.booking_id
            && (!(prevProps.match.params && prevProps.match.params.booking_id) 
                || prevProps.match.params.booking_id !== this.props.match.params.booking_id
            )
        ) this.checkBooking();
    }

    checkBooking = () => {
        if (this.props.match.params && this.props.match.params.booking_id) {
            if (this.props.match.params.booking_id === 'chalet') {
                this.setState({ exist: true, error_exist: null });
            } else {
                // Vérification de l'existance de la réservation
                axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_URL + '/bookings/check-exist/' + this.props.match.params.booking_id 
                }).then((response) => {
                    if (response.data) {
                        this.setState({
                            exist: response.data.exist,
                            booking: response.data,
                            error_exist: response.data.exist ? null : 'Cette réservation n\'existe pas.'
                        });
                    }
                }).catch((error) => {
                    if (error.response.data) {
                        this.setState({
                            exist: false,
                            error_exist: error.response.data.message,
                        });
                    } else {
                        console.error(error.response);
                    }
                });
            }
        }
    }

    calcSliderPos = (event) => {
        return (Math.round((event.offsetX / event.target.clientWidth) * parseInt(event.target.getAttribute('max'), 10) * 2) / 2).toFixed(1);
    }

    onNoteMove = (event) => {
        this.setState({
            note_hover: this.calcSliderPos(event.nativeEvent)
        });
    }

    onNoteChange = (setFieldValue) => {
        this.setState({
            note_selected: true
        });
        setFieldValue('note', this.state.note_hover);
    }

    onNoteOut = () => {
        this.setState({
            note_selected: false
        });
    }

    onEditorStateChange = (editorState, setFieldValue) => {
        setFieldValue('editorState', editorState);
    }

    onSubmit = (review, {resetForm}) => {
        const id = toast.loading('Envoi de votre avis...', { toastId: 'create-review' });
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/reviews/create',
            data: {
                from_chalet: this.props.match.params && this.props.match.params.booking_id === 'chalet',
                booking: this.props.match.params && this.props.match.params.booking_id !== 'chalet'
                    ? this.props.match.params.booking_id
                    : null,
                name: review.name,
                firstname: review.firstname,
                email: review.email,
                note: review.note,
                review: FormatManager.editorToHtml(review.editorState),
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Avis envoyé' + (this.props.match.params && this.props.match.params.booking_id !== 'chalet' ? ', vous receverez un mail lorsque les propriétaires vous réponderont' : ''),
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                resetForm({});
            }
        }).catch((error) => {
            if (error.response && error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible d\'envoyer votre avis',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className={this.state.hidden ? "Review hidden" : "Review"}>
                <div className="content-container">
                    <h2>Laisser un avis</h2>
                    <div className="sections-container">
                        {
                            this.state.error_exist
                            && <div className="section">
                                <div className="text">
                                    <p><strong>{this.state.error_exist}</strong></p>
                                </div>
                            </div>
                        }
                        {
                            this.state.exist
                            &&
                            <div className="create-review">
                                <Formik
                                    initialValues={{ 
                                        name: this.state.booking ? this.state.booking.name : "",
                                        firstname: this.state.booking ? this.state.booking.firstname : "",
                                        email: this.state.booking ? this.state.booking.email : "",
                                        note: 0,
                                        editorState: this.state.editor_state,
                                        accept_data_shared: false
                                    }}
                                    validationSchema={
                                        Yup.object().shape({
                                            name: Yup.string()
                                                .required("Nom requis"),
                                            firstname: Yup.string()
                                                .required("Prénom requis"),
                                            email: Yup.string()
                                                .email("Veuillez saisir une adresse mail"),
                                            note: Yup.number()
                                                .typeError("Note requise")
                                                .min(0.5, "Note requise")
                                                .max(5, "Note requise")
                                                .required("Note requise"),
                                            editorState: Yup.object(),
                                            accept_data_shared: Yup.boolean()
                                                .oneOf([true], "Vous devez accepter que votre avis soit visible publiquement si vous voulez l'envoyer")
                                        })
                                    }
                                    onSubmit={this.onSubmit}
                                >
                                    {({ errors, touched, values, setFieldValue }) => (
                                        <Form className="create-form" autoComplete="off">
                                            {
                                                this.state.error_response
                                                && <div className="form-error">{this.state.error_response}</div>
                                            }
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <label>Nom*</label>
                                                    <div className={touched.name && errors.name ? "field error" : "field"}>
                                                        <Field name="name" />
                                                    </div>
                                                    <ErrorMessage component="div" className="error" name="name" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Prénom*</label>
                                                    <div className={touched.firstname && errors.firstname ? "field error" : "field"}>
                                                        <Field name="firstname" />
                                                    </div>
                                                    <ErrorMessage component="div" className="error" name="firstname" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Mail</label>
                                                    <div className={touched.email && errors.email ? "field error" : "field"}>
                                                        <Field name="email" />
                                                    </div>
                                                    <ErrorMessage component="div" className="error" name="email" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <label>Note*</label>
                                                    <div className={touched.note && errors.note ? "field rating error" : "field rating"}>
                                                        <Field
                                                            className={this.state.note_selected ? "rating selected" : "rating"}
                                                            max="5"
                                                            step="0.5"
                                                            style={{
                                                                "--value": values.note,
                                                                "--value-hover": this.state.note_hover
                                                            }}
                                                            type="range"
                                                            name="note"
                                                            value={values.note}
                                                            onMouseMove={this.onNoteMove}
                                                            onMouseOut={this.onNoteOut}
                                                            onClick={() => this.onNoteChange(setFieldValue)}
                                                        />
                                                    </div>
                                                    <ErrorMessage component="div" className="error" name="note" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <label>Avis</label>
                                                    <div className={touched.editorState && errors.editorState ? "field editor error" : "field editor"}>
                                                        <EditorField
                                                            editorState={values.editorState}
                                                            disabledBlock={['header-one', 'header-two', 'header-three', 'header-four', 'header-five', 'header-six']}
                                                            disabledInline={[]}
                                                            onChange={setFieldValue}
                                                            onEditorStateChange={(nextEditorState) => this.onEditorStateChange(nextEditorState, setFieldValue)}
                                                        />
                                                    </div>
                                                    <ErrorMessage component="div" className="error" name="editorState" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                            <div className="form-group checkbox">
                                                    <div className={touched.accept_data_shared && errors.accept_data_shared ? "field checkbox accept-data error" : "field checkbox accept-data"}>
                                                        <label>
                                                            <span className="checkbox-input">
                                                                <Field type="checkbox" name="accept_data_shared" />
                                                                <span className="checkbox-control"><Icon fontSize="tiny">checked</Icon></span>
                                                                <span className="checkbox-label">
                                                                    En soumettant ce formulaire, vous acceptez que les données saisies soient enregistrées et, hormis vos nom et adresse mail, visibles publiquement. L'adresse mail (non obligatoire) nous permettra de vous notifier de notre réponse.
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <ErrorMessage component="div" className="error" name="accept_data_shared" />
                                                </div>
                                            </div>
                                            <Field name="price" type="hidden" />
                                            <div className="buttons-container">
                                                {
                                                    values.price
                                                    && <div className="pricing">
                                                        <strong>{FormatManager.priceFormat(values.price)}</strong> environ<br/>
                                                        {
                                                            values.menage
                                                            &&<i>(dont {FormatManager.priceFormat(this.state.forfait_menage.forfait_menage)} de forfait ménage)</i>
                                                        }
                                                    </div>
                                                }
                                                <button type="submit">
                                                    <Icon fontSize="medium">rate_review</Icon><span>Envoyer votre avis</span>
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Review);