import React, { useState, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';

import './Map.scss';

const libraries = [ 'places' ];

function Map() {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ infos, setInfos ] = useState(null);
    const infoWindow = useRef(null);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
        libraries: libraries
    });

    const onLoad = (map) => {
        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails({
            placeId: process.env.REACT_APP_PLACE_ID,
            fields: [ 'address_component', 'name', 'url' ]
        }, (places, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setInfos(places);     
                setIsOpen(true);
                // Suppression du focus sur le lien
                infoWindow.current.state.infoWindow.shouldFocus = false;
            }
        });
    }

    const renderMap = () => {
        return (
            <GoogleMap
                mapContainerClassName="Map"
                center={{ 
                    lat: 48.1332231,
                    lng: 6.9141866
                }}
                zoom={12}
                options={{
                    fullscreenControl: false,
                    mapTypeControl: false,
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    streetViewControl: false
                }}
                onLoad={onLoad}
                onClick={() => setIsOpen(false)}
                onDragStart={() => setIsOpen(false)}
            >
                {
                    infos
                    && <Marker
                        title={infos.name}
                        position={{ 
                            lat: 48.1332231,
                            lng: 6.9141866
                        }}
                        onClick={() => setIsOpen(true)}
                    >
                        {
                            isOpen
                            && <InfoWindow
                                ref={infoWindow}
                                onCloseClick={() => setIsOpen(false)}
                            >
                                <div className="infos">
                                    <h4>{infos.name}</h4>
                                    <p>
                                        {
                                            infos.address_components
                                            && <>
                                                {infos.address_components[0].long_name} {infos.address_components[1].long_name}<br/>
                                                {infos.address_components[6].long_name} {infos.address_components[2].long_name}<br/>
                                                {infos.address_components[3].long_name}, {infos.address_components[4].long_name}, {infos.address_components[5].long_name}
                                            </>
                                        }
                                    </p>
                                    {
                                        infos.url
                                        && <a href={infos.url} target="_blank" rel="noreferrer" title="Voir sur Google Maps">Voir sur Google Maps</a>
                                    }
                                </div>
                            </InfoWindow>
                        }
                    </Marker>
                }
            </GoogleMap>
        );
    }

    if (loadError) {
        return <div>La carte ne peut pas être chargée pour le moment, désolé.</div>
    }
    
    return isLoaded ? renderMap() : null;
}

export default Map;