import React from 'react';
import Icon from '@mui/material/Icon';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Parser from 'html-react-parser';

import FormatManager from '../../../utils/FormatManager';
import './FullscreenSlideshow.scss';

class FullscreenSlideshow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            current_slide: 0,
            transition_time: 800
        };
    }

    componentDidMount() {
        if ((this.props.image_index || this.props.image_index === 0 ) && this.props.image_index !== this.state.current_slide) this.setState({ 
            current_slide: this.props.image_index,
            transition_time: 0
        }, () => setTimeout(() => this.setState({ transition_time: 800 }), 400));
        document.addEventListener('keydown', this.onEscClick, false);
        setTimeout(() => this.setState({ hidden: false }), 0);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.image_index || this.props.image_index === 0 ) && this.props.image_index !== prevProps.image_index) this.setState({ 
            current_slide: this.props.image_index,
            transition_time: 0
        }, () => setTimeout(() => this.setState({ transition_time: 800 }), 400));
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onEscClick, false);
    }

    getClassName = () => {
        return this.state.hidden ? "FullscreenSlideshow hidden" : "FullscreenSlideshow";
    }

    onEscClick = (e) => {
        if (e.keyCode === 27) this.onClose();
    }

    onClose = () => {
        this.setState({ hidden: true });
        this.props.onClose();
    }

    onSlideChange = (index) => {
        if (index !== this.state.current_slide) this.setState({ current_slide: index });
    }

    render() {
        return (
            <div className={this.getClassName()}>
                <div className="slideshow-block">
                    <button onClick={() => this.onClose()} className="close-button"><Icon fontSize="medium">close</Icon></button>
                    <Carousel
                        transitionTime={this.state.transition_time}
                        axis="horizontal"
                        showStatus={false}
                        showThumbs={true}
                        thumbWidth="106"
                        showIndicators={false}
                        selectedItem={this.state.current_slide}
                        onChange={this.onSlideChange}
                        // swipeScrollTolerance={100}
                        preventMovementUntilSwipeScrollTolerance={true}
                        useKeyboardArrows={true}
                        renderArrowPrev={(onClickHandler, hasPrev, label) => (
                            <button type="button" className="control-arrow control-prev" onClick={onClickHandler} disabled={!hasPrev} title="Image précédente" aria-label={label}>
                                <Icon fontSize="large">navigate_before</Icon>
                            </button>
                        )}
                        renderArrowNext={(onClickHandler, hasNext, label) => (
                            <button type="button" className="control-arrow control-next" onClick={onClickHandler} disabled={!hasNext} title="Image suivante" aria-label={label}>
                                <Icon fontSize="large">navigate_next</Icon>
                            </button>
                        )}
                    >
                        {
                            this.props.images && this.props.images.map((image, index) => (
                                <div className="slide-image" key={index}>
                                    <img 
                                        srcSet={FormatManager.imageSrcSet(image.paths)}
                                        src={image.paths.w1920}
                                        alt={image.name}
                                    />
                                    <div className="legend">
                                        <h3>{Parser(image.name)}</h3>
                                        {image.desc && Parser(image.desc)}
                                    </div>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        );
    }
}

export default FullscreenSlideshow;