import React, { createRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Icon from '@mui/material/Icon';

import './Navigation.scss';

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reduced_menu: window.innerWidth < 768,
            menu_open: false
        };

        this.openMenuRef = createRef();
        this.smallMenuRef = createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        document.addEventListener('mousedown', this.onClickOutside);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        document.removeEventListener('mousedown', this.onClickOutside);
    }

    updateDimensions = () => {
        if (window.innerWidth < 768 && !this.state.reduced_menu) {
            this.setState({ 
                reduced_menu: true,
                menu_open: false
            });
        } else if (window.innerWidth >= 768 && this.state.reduced_menu) {
            this.setState({
                reduced_menu: false,
                menu_open: false
            });
        }
    }

    onClickOutside = (e) => {
        if (
            this.state.menu_open 
            && this.smallMenuRef.current && !this.smallMenuRef.current.contains(e.target) 
            && this.openMenuRef.current && !this.openMenuRef.current.contains(e.target)
        ) this.setState({ menu_open: false });
    }

    getClassName = () => {
        return this.props.location.pathname === '/reserver' 
            || this.props.location.pathname === '/mentions-legales' 
            || this.props.location.pathname === '/cg-vente' 
            || this.props.location.pathname.includes('/avis/')
                ? 'Navigation scrolled'
                : this.props.scrolled
                    ? 'Navigation scrolled'
                    : 'Navigation';
    }

    getItemClassName = (className) => {
        return this.props.location.pathname === '/' 
            && this.props.location.pathname === className
            ? 'active' 
            : this.props.location.pathname.includes(className)
                && this.props.location.pathname !== '/' && className !== '/' 
                ? 'active' 
                : '';
    }

    setMenuOpen = (menu_open) => {
        this.setState({ menu_open: menu_open });
    }

    render() {
        return (
            <div className={this.getClassName()}>
                <nav>
                    <ul>
                        <li className="logo-small-container">
                            <img src="/logo/logo_simple.svg" alt="Logo simple 'Au Refuge des Chamois'" />
                        </li>
                        {
                            !this.state.reduced_menu
                            && <>
                                <li className={this.getItemClassName("/")}>
                                    <Link to="/">
                                        <span className="chamois"><img src="/chamois.svg" alt="" /></span>
                                        <span className="name">Accueil</span>
                                    </Link>
                                </li>
                                <li className={this.getItemClassName("/le-chalet")}>
                                    <Link to="/le-chalet">
                                        <span className="chamois"><img src="/chamois.svg" alt="" /></span>
                                        <span className="name">Le Chalet</span>
                                    </Link>
                                </li>
                                <li className="logo-container">
                                    <img src="/logo/logo.svg" alt="Logo 'Au Refuge des Chamois'" className="logo-foreground" />
                                    <img src="/logo/logo_background.svg" alt="Logo 'Au Refuge des Chamois'" className="logo-background" />
                                </li>
                                <li className={this.getItemClassName("/activites")}>
                                    <Link to="/activites">
                                        <span className="chamois"><img src="/chamois.svg" alt="" /></span>
                                        <span className="name">Activités</span>
                                    </Link>
                                </li>
                                <li className={this.getItemClassName("/reserver")}>
                                    <Link to="/reserver">
                                        <span className="chamois"><img src="/chamois.svg" alt="" /></span>
                                        <span className="name">Réserver</span>
                                    </Link>
                                </li>
                            </>
                        }
                        {
                            this.state.reduced_menu
                            && <>
                                <li className={this.state.menu_open ? "open-menu open" : "open-menu"}>
                                    <button type="button" onClick={() => this.setMenuOpen(!this.state.menu_open)} ref={this.openMenuRef}>
                                        <Icon fontSize="medium">{this.state.menu_open ? "close" : "menu"}</Icon>
                                    </button>
                                </li>
                            </>
                        }
                    </ul>
                    {
                        this.state.reduced_menu
                        && <div className={this.state.menu_open ? "small-menu open" : "small-menu"} ref={this.smallMenuRef}>
                            <ul>
                                <li className={this.getItemClassName("/")}>
                                    <Link to="/" onClick={() => this.setMenuOpen(false)}>Accueil</Link>
                                </li>
                                <li className={this.getItemClassName("/le-chalet")}>
                                    <Link to="/le-chalet" onClick={() => this.setMenuOpen(false)}>Le Chalet</Link>
                                </li>
                                <li className={this.getItemClassName("/activites")}>
                                    <Link to="/activites" onClick={() => this.setMenuOpen(false)}>Activités</Link>
                                </li>
                                <li className={this.getItemClassName("/reserver")}>
                                    <Link to="/reserver" onClick={() => this.setMenuOpen(false)}>Réserver</Link>
                                </li>
                            </ul>
                        </div>
                    }
                </nav>
            </div>
        );
    }
}

export default withRouter(Navigation);