import React from 'react';
import axios from 'axios';
import { animateScroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import LazyLoad from 'react-lazyload';

import Slideshow from './slideshow/Slideshow';
import Map from './map/Map';
import Reviews from './reviews/Reviews';
import FormatManager from '../../utils/FormatManager';
import IMAGES_POSITION from '../../constants/images_position.enum';
import './Home.scss';

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            home: null,
            chalet: null,
            activities: null
        }
    }

    componentDidMount() {
        this.props.onFullscreenChange(false);
        this.loadData();
        animateScroll.scrollTo(0, {
            duration: 0
        });
        setTimeout(() => this.setState({ hidden: false }), 0);
    }

    loadData = () => {
        // Récupération des données pour les différentes pages
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/pages/for-home'
        }).then((response) => {
            if (response.data) {
                this.setState({
                    home: response.data.home,
                    chalet: response.data.chalet,
                    activities: response.data.activities
                });
            }
        });
    }

    getSectionClassName = (images_position, images) => {
        if (!images || images.length === 0) return 'section';
        switch(images_position) {
            case IMAGES_POSITION.LEFT:
                return 'section images-left';
            case IMAGES_POSITION.UNDER:
                return 'section images-under';
            case IMAGES_POSITION.RIGHT:
                return 'section images-right';
            default:
                return 'section'
        }
    }

    render() {
        return (
            <div className={this.state.hidden ? "Home hidden" : "Home"}>
                <Slideshow />
                <div className="content-container">
                    {
                        this.state.home && this.state.home.sections && this.state.home.sections.length > 0
                        && <article className="home-content">
                            { this.state.home.sections.map((section, index) => (
                                <section className={this.getSectionClassName(section.images_position, section.images)} key={this.props.page + "-" + index}>
                                    <div className="text">{section.text && Parser(section.text)}</div>
                                    {
                                        section.images && section.images.length > 0
                                        && <div className="images-container">
                                            {section.images.map((image, i) => (
                                                <div className="image" key={"home-" + index + "." + i}>
                                                    <LazyLoad offset={200} placeholder={<div className="lazyload-image"></div>} once>
                                                        <img
                                                            srcSet={FormatManager.imageSrcSet(image.paths)}
                                                            sizes={ 
                                                                "(max-width: 449px) 500px," +
                                                                "(max-width: 767px) 719px," +
                                                                "(max-width: 899px) 803px," +
                                                                "(max-width: 1279px) " + Math.round(15.4 * image.aspect_ratio * 10) / 10 + "rem, " +
                                                                Math.round(19.2 * image.aspect_ratio * 10) / 10 + "rem" 
                                                            }
                                                            src={image.paths.w1920}
                                                            alt={image.name}
                                                        />
                                                    </LazyLoad>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </section>
                            )) }
                        </article>
                    }
                    {
                        (this.state.chalet || this.state.activities)
                        && <LazyLoad height={600} offset={200} once>
                            <div className={this.state.home && this.state.home.sections && this.state.home.sections.length > 0 ? "blocks-container" : "blocks-container no-home-content"}>
                                {
                                    this.state.chalet
                                    && <Link to="/le-chalet" className="block">
                                        {
                                            this.state.chalet.header_image && this.state.chalet.header_image.paths
                                            && <div className="image-container">
                                                <LazyLoad offset={200} placeholder={<div className="block-image-placeholder"></div>} once>
                                                    <img 
                                                        srcSet={FormatManager.imageSrcSet(this.state.chalet.header_image.paths)}
                                                        sizes={ 
                                                            "(max-width: 499px) " + Math.round(24 * this.state.chalet.header_image.aspect_ratio * 10) / 10 + "rem," +
                                                            "(max-width: 767px) " + Math.round(32.4 * this.state.chalet.header_image.aspect_ratio * 10) / 10 + "rem," +
                                                            "(max-width: 1023px) " + Math.round(18 * this.state.chalet.header_image.aspect_ratio * 10) / 10 + "rem," +
                                                            "(max-width: 1279px) " + Math.round(19.2 * this.state.chalet.header_image.aspect_ratio * 10) / 10 + "rem," +
                                                            Math.round(24 * this.state.chalet.header_image.aspect_ratio * 10) / 10 + "rem" 
                                                        }
                                                        src={this.state.chalet.header_image.paths.w1920}
                                                        alt={this.state.chalet.header_image.name}
                                                        className="block-image"
                                                    />
                                                </LazyLoad>
                                            </div>
                                        }
                                        <div className="description">
                                            <h2>Le Chalet</h2>
                                            {this.state.chalet.desc && Parser(this.state.chalet.desc)}
                                            <span className="click-to">Cliquez pour en savoir plus</span>
                                        </div>
                                    </Link>
                                } 
                                {
                                    this.state.chalet
                                    && <Link to="/activites" className="block">
                                        {
                                            this.state.activities.header_image && this.state.activities.header_image.paths
                                            && <div className="image-container">
                                                <LazyLoad offset={200} placeholder={<div className="block-image-placeholder"></div>} once>
                                                    <img 
                                                        srcSet={FormatManager.imageSrcSet(this.state.activities.header_image.paths)}
                                                        sizes={ 
                                                            "(max-width: 499px) " + Math.round(24 * this.state.activities.header_image.aspect_ratio * 10) / 10 + "rem," +
                                                            "(max-width: 767px) " + Math.round(32.4 * this.state.activities.header_image.aspect_ratio * 10) / 10 + "rem," +
                                                            "(max-width: 1023px) " + Math.round(18 * this.state.activities.header_image.aspect_ratio * 10) / 10 + "rem," +
                                                            "(max-width: 1279px) " + Math.round(19.2 * this.state.activities.header_image.aspect_ratio * 10) / 10 + "rem," +
                                                            Math.round(24 * this.state.activities.header_image.aspect_ratio * 10) / 10 + "rem" 
                                                        }
                                                        src={this.state.activities.header_image.paths.w1920}
                                                        alt={this.state.activities.header_image.name}
                                                        className="block-image"
                                                    />
                                                </LazyLoad>
                                            </div>
                                        }
                                        <div className="description">
                                            <h2>Activités</h2>
                                            {this.state.activities.desc && Parser(this.state.activities.desc)}
                                            <span className="click-to">Cliquez pour en savoir plus</span>
                                        </div>
                                    </Link>
                                }
                            </div>
                        </LazyLoad>
                    }
                    <LazyLoad height={800} offset={200} once>
                        <article className="localisation-container">
                            <section className="localisation">
                                <h2>Où sommes-nous ?</h2>
                                <div className="text">
                                    <p>Le <strong>Refuge des Chamois</strong> est situé au <strong>47B Les Trexons, 88430 Gerbépal</strong>, entre la commune même de Gerbépal et le lieu-dit de la Basse de Martimpré.</p>
                                    <p>Situé sur la route entre Saint-Dié-des-Vosges et Gérardmer, notre chalet est proche de tous les services dont vous aurez besoin.</p>
                                </div>
                            </section>
                            <LazyLoad height={400} offset={200}>
                                <Map />
                            </LazyLoad>
                        </article>
                    </LazyLoad>
                    <LazyLoad offset={200} placeholder={<div className="lazyload-placeholder" style={{ height: 400 }}></div>} once>
                        <Reviews limit={5}/>
                    </LazyLoad>
                </div>
            </div>
        );
    }
}

export default Home;