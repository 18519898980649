import React from 'react';
import scrollLock from 'scroll-lock';
import { ScrollLocky } from 'react-scroll-locky';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Navigation from './components/navigation/Navigation';
import Review from './components/review/Review';
import Booking from './components/booking/Booking';
import Page from './components/page/Page';
import Home from './components/home/Home';
import NotFound from './components/not_found/NotFound';
import Footer from './components/footer/Footer';
import ToTop from './components/to_top/ToTop';
import BookingShortcut from './components/booking_shortcut/BookingShortcut';
import PAGES from './constants/pages.enum';
import './App.scss';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrolled: false,
            fullscreen: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollListener);
        // Gestion de l'écran de chargement
        const load_screen = document.getElementById('load-screen');
        if (load_screen) {
            scrollLock.disablePageScroll();
            setTimeout(() => {
                load_screen.classList.add('loaded');
                scrollLock.enablePageScroll();
            }, 3000);
            setTimeout(() => load_screen.outerHTML = '', 4000);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListener);
    }

    scrollListener = () => {
        let heightToHideFrom = 256;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
           
        if (winScroll > heightToHideFrom) { 
            if (!this.state.scrolled) this.setState({ scrolled: true });
        } else {
            this.setState({ scrolled: false });
        }  
    }

    onFullscreenChange = (fullscreen) => {
        if (fullscreen !== this.state.fullscreen) this.setState({ fullscreen: fullscreen });
    }

    render() {
        return (
            <ScrollLocky enabled={this.state.fullscreen} isolation={false} className="App">
                <Router>
                    <Navigation scrolled={this.state.scrolled} />
                    <Switch>
                        <Route path="/avis/:booking_id">
                            <Review onFullscreenChange={this.onFullscreenChange} />
                        </Route>
                        <Route path="/cg-vente" exact>
                            <Page page={PAGES.TERMS_OF_SALES} onFullscreenChange={this.onFullscreenChange} />
                        </Route>
                        <Route path="/mentions-legales" exact>
                            <Page page={PAGES.LEGAL_NOTICE} onFullscreenChange={this.onFullscreenChange} />
                        </Route>
                        <Route path="/reserver" exact>
                            <Booking onFullscreenChange={this.onFullscreenChange} />
                        </Route>
                        <Route path="/activites" exact>
                            <Page page={PAGES.ACTIVITIES} onFullscreenChange={this.onFullscreenChange} />
                        </Route>
                        <Route path="/le-chalet" exact>
                            <Page page={PAGES.CHALET} onFullscreenChange={this.onFullscreenChange} />
                        </Route>
                        <Route path="/" exact>
                            <Home onFullscreenChange={this.onFullscreenChange} />
                        </Route>
                        <Route path="*">
                            <NotFound onFullscreenChange={this.onFullscreenChange} />
                        </Route>
                    </Switch>
                    <Footer />
                    <ToTop scrolled={this.state.scrolled} />
                    <BookingShortcut scrolled={this.state.scrolled} />
                </Router>
                <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
            </ScrollLocky>
        );
    }
}

export default App;