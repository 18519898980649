import React from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Icon from '@mui/material/Icon';

import ReviewBlock from './review_block/ReviewBlock';
import './Reviews.scss';

class Reviews extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reviews: null,
            page_count: null,
            offset: 0,
            mean_note: null
        };
    }

    componentDidMount() {
        this.loadData();
        this.getMeanNote();
    }

    loadData = (offset = 0) => {
        // Récupération des avis
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/reviews/' + this.props.limit + '/' + offset
        }).then((response) => {
            if (response.data) {
                this.setState({
                    reviews: response.data.reviews,
                    page_count: Math.ceil(response.data.total / this.props.limit),
                    offset: offset
                });
            }
        });
    }

    getMeanNote = () => {
        // Récupération de la note moyenne
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/reviews/mean-note'
        }).then((response) => {
            if (response.data) {
                this.setState({
                    mean_note: response.data.mean_note
                });
            }
        });
    }

    onPageChange = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.props.limit);

        this.loadData(offset);
    }

    render() {
        return (
            <div className="Reviews">
                <h2>
                    <span>Avis</span>
                    {
                        this.state.mean_note
                        && <div className="mean-note">
                            {
                                this.state.mean_note >= 1 
                                    ? <Icon fontSize="medium">star_rate</Icon>
                                    : this.state.mean_note === 0.5
                                        ? <Icon fontSize="medium">star_half</Icon>
                                        : <Icon fontSize="medium">star_border</Icon>
                            }
                            {
                                this.state.mean_note >= 2
                                    ? <Icon fontSize="medium">star_rate</Icon>
                                    : this.state.mean_note === 1.5
                                        ? <Icon fontSize="medium">star_half</Icon>
                                        : <Icon fontSize="medium">star_border</Icon>
                            }
                            {
                                this.state.mean_note >= 3 
                                    ? <Icon fontSize="medium">star_rate</Icon>
                                    : this.state.mean_note === 2.5
                                        ? <Icon fontSize="medium">star_half</Icon>
                                        : <Icon fontSize="medium">star_border</Icon>
                            }
                            {
                                this.state.mean_note >= 4
                                    ? <Icon fontSize="medium">star_rate</Icon>
                                    : this.state.mean_note === 3.5
                                        ? <Icon fontSize="medium">star_half</Icon>
                                        : <Icon fontSize="medium">star_border</Icon>
                            }
                            {
                                this.state.mean_note >= 5
                                    ? <Icon fontSize="medium">star_rate</Icon>
                                    : this.state.mean_note === 4.5
                                        ? <Icon fontSize="medium">star_half</Icon>
                                        : <Icon fontSize="medium">star_border</Icon>
                            }
                        </div>
                    }
                </h2>
                <div className="reviews-container">
                    {
                        (this.state.reviews && this.state.reviews.length > 0
                        && this.state.reviews.map((review, index) => (
                            <ReviewBlock review={review} limit={2} key={index + this.state.offset} />
                        ))) || <p className="no-reviews">Aucun avis</p>
                    }
                </div>
                {
                    this.state.reviews && this.state.reviews.length > 0 && this.state.page_count
                    && <ReactPaginate
                        previousLabel={<div className="paginate-previous">
                            <Icon fontSize="small">navigate_before</Icon>
                        </div>}
                        nextLabel={<div className="paginate-next">
                            <Icon fontSize="small">navigate_next</Icon>
                        </div>}
                        breakLabel="..."
                        breakClassName="paginate-break"
                        pageCount={this.state.page_count}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.onPageChange}
                        containerClassName="paginate"
                        activeClassName='active'
                    />
                }
            </div>
        );
    }
}

export default Reviews;