import React from 'react';
import { useField, useFormikContext } from 'formik';
import PhoneInput, { formatPhoneNumberIntl }  from 'react-phone-number-input'
import fr from 'react-phone-number-input/locale/fr';
import 'react-phone-number-input/style.css';

const PhoneInputField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
        <PhoneInput
            {...field}
            {...props}
            labels={fr}
            defaultCountry="FR"
            international
            onChange={(value) => setFieldValue(field.name, formatPhoneNumberIntl(value) || undefined)}
        />
    );
};

export default PhoneInputField;