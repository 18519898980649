import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import 'moment/locale/fr';
import Icon from '@mui/material/Icon';

import FormatManager from '../../utils/FormatManager';
import './BookingShortcut.scss';
import '../../utils/date_picker_field/DatePickerField.scss';

class BookingShortcut extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mobile_shortcut: window.innerWidth < 768,
            disabled: false,
            hidden: true,
            closed: true,
            inavailable_dates: null,
            error_response: null,
            from: null,
            to: null,
            price: null
        };

        this.shortcutRef = createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        document.addEventListener('mousedown', this.onClickOutside);
        this.getInavailableDates();
        if (this.props.location.pathname === '/le-chalet' || this.props.location.pathname === '/activites') this.setState({ hidden: false });
    }

    componentDidUpdate(prevProps, prevState) {
        // Affichage permanent de BookingShortcut pour Le Chalet et Activités
        if (
            (this.props.location.pathname === '/le-chalet' || this.props.location.pathname === '/activites')
            && prevProps.location.pathname !== this.props.location.pathname
        ) this.setState({ hidden: false });
        // Affichage lors du scroll pour Home
        if (
            this.props.scrolled && this.props.location.pathname !== '/reserver' 
            && this.props.scrolled && this.props.location.pathname !== '/mentions-legales' 
            && this.props.scrolled && this.props.location.pathname !== '/cg-vente' 
            && !this.props.location.pathname.includes('/avis/') && this.state.hidden
        ) this.setState({ hidden: false });
        // Cacher pour Home
        if (!this.props.scrolled && !this.state.hidden && this.props.location.pathname !== '/le-chalet' && this.props.location.pathname !== '/activites') this.setState({ 
            hidden: true,
            closed: true
        });

        // Calcul du prix lors de la sélection des dates
        if (this.state.from && this.state.to && (prevState.from !== this.state.from || prevState.to !== this.state.to)) this.calculatePrice();
        // Suppression du message d'erreur et du prix lors de la désélection des dates
        if ((!this.state.from || !this.state.to) && (this.state.error_response || this.state.price)) this.setState({ error_response: null, price: null });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        document.removeEventListener('mousedown', this.onClickOutside);
    }

    updateDimensions = () => {
        if (window.innerWidth < 768 && !this.state.mobile_shortcut) {
            this.setState({ 
                mobile_shortcut: true,
                disabled: true
            }, () => setTimeout(() => this.setState({ disabled: false }), 500));
        } else if (window.innerWidth >= 768 && this.state.mobile_shortcut) {
            this.setState({
                mobile_shortcut: false,
                disabled: true
            }, () => setTimeout(() => this.setState({ disabled: false }), 500));
        }
    }

    onClickOutside = (e) => {
        if (
            !this.state.closed
            && this.shortcutRef.current && !this.shortcutRef.current.contains(e.target)
        ) this.setState({ closed: true });
    }

    getInavailableDates = () => {
        // Récupération des dates indisponibles
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/events/inavailable-dates'
        }).then((response) => {
            if (response.data) {
                this.setState({
                    inavailable_dates: FormatManager.datesArray(response.data.inavailableDates)
                });
            }
        });
    }

    calculatePrice = () => {
        if (this.state.from && this.state.to && this.state.from.getTime() !== this.state.to.getTime()) {
            // Calcul du prix de la réservation
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/bookings/price/' + this.state.from + '/' + this.state.to
            }).then((response) => {
                if (response.data) {
                    this.setState({
                        price: response.data.price || null,
                        error_response: null
                    });
                }
            }).catch((error) => {
                if (error.response.data) {
                    this.setState({
                        price: null,
                        error_response: error.response.data.message,
                    });
                } else {
                    console.error(error.response);
                }
            });
        }
    }

    getClassName = () => {
        let className = "BookingShortcut";
        if (this.state.hidden) className += " hidden";
        if (this.state.closed) className += " closed";
        if (this.state.disabled) className += " disabled";
        return className;
    }

    onOpenCloseClick = () => {
        this.setState({ closed: !this.state.closed });
    }

    handleDayClick = (day, modifier) => {
        if (day && !modifier.disabled) {
            day.setHours(0,0,0,0);
            const range = DateUtils.addDayToRange(day, this.state);
            this.setState(range);
        }
    }

    onBookingClick = () => {
        if (this.state.from && this.state.to && this.state.price && this.state.from.getTime() !== this.state.to.getTime()) this.props.history.push('/reserver', {
            in_date: this.state.from,
            out_date: this.state.to
        });
    }

    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        let tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);

        return (
            <div className={this.getClassName()} ref={this.shortcutRef}>
                <button type="button" className="open-close-button" onClick={this.onOpenCloseClick}>
                    {
                        (this.state.closed
                            && <div className="rotate-text">
                                <Icon fontSize="small">arrow_drop_up</Icon>
                                <div className="open-close-text">Réserver</div>
                                <Icon fontSize="small">arrow_drop_up</Icon>
                            </div>
                        ) || <div className="rotate-text">
                            <Icon fontSize="small">arrow_drop_down</Icon>
                            <div className="open-close-text">Fermer</div>
                            <Icon fontSize="small">arrow_drop_down</Icon>
                        </div>
                    }
                </button>
                <div className="picker-container">
                    <DayPicker
                        className="dates-range"
                        format="DD/MM/yyyy"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        showOutsideDays={true}
                        localeUtils={MomentLocaleUtils}
                        locale="fr"
                        disabledDays={this.state.inavailable_dates ? this.state.inavailable_dates.concat({ before: from || tomorrow }) : []}
                        month={tomorrow}
                        fromMonth={from || tomorrow}
                        selectedDays={[from, { from: from, to: to }]}
                        modifiers={modifiers}
                        fixedWeeks={true}
                        onDayClick={(day, modifier) => this.handleDayClick(day, modifier)}
                    />
                    {
                        this.state.price
                        && <div className="pricing">
                            <strong>{FormatManager.priceFormat(this.state.price)}</strong> environ
                        </div>
                    }
                    {
                        this.state.error_response
                        && <div className="error-response">
                            {this.state.error_response}
                        </div>
                    }
                    {
                        !this.state.error_response
                        && <button type="button" className="booking-button" onClick={this.onBookingClick} disabled={!this.state.price}>
                            <Icon fontSize="small">night_shelter</Icon><span>Réserver</span>
                        </button>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(BookingShortcut);