import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { animateScroll } from 'react-scroll';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import Icon from '@mui/material/Icon';
import moment from 'moment';
import { toast } from 'react-toastify';

import DatePickerField from '../../utils/date_picker_field/DatePickerField';
import PhoneInputField from '../../utils/PhoneInputField';
import EditorField from '../../utils/editor_field/EditorField';
import FormatManager from '../../utils/FormatManager';
import './Booking.scss';

class Booking extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            contact: null,
            forfait_menage: null,
            editor_state: FormatManager.htmlToEditor(),
            inavailable_dates: null,
            error_response: null,
            in_date: null,
            out_date: null,
            menage: false
        };

        this.bookingForm = createRef();
        this.outDateRef = createRef();
    }

    componentDidMount() {
        this.props.onFullscreenChange(false);
        this.loadData();
        this.getInavailableDates();
        if (this.props.location.state) setTimeout(() => {
            this.bookingForm.current.setFieldValue('in_date', this.props.location.state.in_date);
            this.bookingForm.current.setFieldValue('out_date', this.props.location.state.out_date);
            this.setState(this.props.location.state, () => this.calculatePrice(this.bookingForm.current.setFieldValue));
        }, 1);
        animateScroll.scrollTo(0, {
            duration: 0
        });
        setTimeout(() => this.setState({ hidden: false }), 0);
    }

    loadData = () => {
        // Récupération des coordonnées de contact
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/settings/contact'
        }).then((response) => {
            if (response.data) {

                this.setState({
                    contact: response.data
                });
            }
        });
        
        // Récupération du prix du forfait ménage
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/settings/forfait-menage'
        }).then((response) => {
            if (response.data) {

                this.setState({
                    forfait_menage: response.data
                });
            }
        });
    }

    getInavailableDates = () => {
        // Récupération des dates indisponibles
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/events/inavailable-dates'
        }).then((response) => {
            if (response.data) {
                this.setState({
                    inavailable_dates: FormatManager.datesArray(response.data.inavailableDates)
                });
            }
        });
    }

    calculatePrice = (setFieldValue) => {
        if (this.state.in_date && this.state.out_date) {
            // Calcul du prix de la réservation
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/bookings/price/' + this.state.in_date + '/' + this.state.out_date + (this.state.menage ? '/menage' : ''),
            }).then((response) => {
                if (response.data) {
                    setFieldValue('price', response.data.price);
                    this.setState({
                        error_response: null
                    });
                }
            }).catch((error) => {
                if (error.response.data) {
                    setFieldValue('price', '');
                    this.setState({
                        error_response: error.response.data.message,
                    });
                } else {
                    console.error(error.response);
                }
            });
        }
    }

    onSubmit = (booking, {resetForm}) => {
        const id = toast.loading('Envoi de votre demande de réservation...', { toastId: 'create-booking' });
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/bookings/create',
            data: {
                name: booking.name,
                firstname: booking.firstname,
                in_date: FormatManager.dateDBFormat(booking.in_date),
                out_date: FormatManager.dateDBFormat(booking.out_date),
                address: booking.address,
                postal_code: booking.postal_code,
                city: booking.city,
                country: booking.country,
                email: booking.email,
                phone: booking.phone,
                nb_adults: booking.nb_adults,
                nb_childs: booking.nb_childs,
                menage: booking.menage,
                price: booking.price ? Number(String(booking.price).replace(/,/, '.')) : null,
                message: FormatManager.editorToHtml(booking.editorState),
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null,
                    in_date: null,
                    out_date: null,
                    menage: false
                });
                toast.update(id, {
                    render: 'Demande de réservation envoyée, vous allez recevoir un récapitulatif de votre demande par mail',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.getInavailableDates();
                resetForm({});
            }
        }).catch((error) => {
            if (error.response && error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible d\'envoyer votre demande de réservation',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    showStartMonth = () => {
        if (!this.state.in_date) return;
        if (moment(this.state.out_date).diff(moment(this.state.in_date), 'months') < 1) this.outDateRef.current.getDayPicker().showMonth(this.state.in_date);
    }
    
    handleStartChange = (in_date, setFieldValue) => {
        if (in_date) in_date.setHours(0,0,0,0);
        this.setState({ in_date: in_date }, () => this.calculatePrice(setFieldValue));
    }
    
    handleEndChange = (out_date, setFieldValue) => {
        if (out_date) out_date.setHours(0,0,0,0);
        this.setState({ out_date: out_date }, () => {
            this.showStartMonth();
            this.calculatePrice(setFieldValue);
        });
    }

    handleMenageChange = (menage, setFieldValue) => {
        this.setState({ menage: menage }, () => this.calculatePrice(setFieldValue));
    }

    render() {
        const { in_date, out_date } = this.state;
        const modifiers = { start: in_date, end: out_date };
        let tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);

        return (
            <div className={this.state.hidden ? "Booking hidden" : "Booking"}>
                <article className="content-container">
                    <h2>Réserver</h2>
                    <div className="sections-container">
                        <section className="section">
                            <div className="text">
                                <p>Vous souhaitez séjourner au <strong>Refuge des Chamois</strong> ?</p>
                                {
                                    this.state.contact && (this.state.contact.phone || this.state.contact.email)
                                    && <p>
                                        Contactez nous {
                                            this.state.contact.phone
                                            && <>
                                                par téléphone au <a href={"tel:" + this.state.contact.phone} title="Téléphonez nous">{formatPhoneNumberIntl(this.state.contact.phone)}</a> 
                                            </>
                                        }
                                        {
                                            this.state.contact.phone && this.state.contact.email
                                            && <> ou </>
                                        }
                                        {
                                            this.state.contact.email
                                            && <>
                                                par mail à <a href={"mailto:" + this.state.contact.email} title="Envoyez nous un mail">{this.state.contact.email}</a>
                                            </>
                                        }.
                                    </p>
                                }
                                <p>Vous pouvez {this.state.contact ? "aussi" : ""} utiliser le formulaire ci-dessous pour consulter les disponibilités du chalet, avoir une estimation du prix de votre séjour et faire votre demande de réservation.</p>
                                <p>La réservation n'est pas immédiate et ne sera validée qu'après prise de contact de notre part.</p>
                                <p>Sauf indication contraire de notre part, l'arrivée se fait à partir de <strong>16h00</strong> et le départ doit être effectué avant <strong>10h00</strong>.</p>
                            </div>
                        </section>
                        <div className="create-booking">
                            <Formik
                                initialValues={{ 
                                    in_date: in_date,
                                    out_date: out_date,
                                    menage: this.state.menage,
                                    nb_adults: 0,
                                    nb_childs: 0,
                                    name: "",
                                    firstname: "",
                                    email: "",
                                    phone: "",
                                    address: "",
                                    postal_code: "",
                                    city: "",
                                    country: "",
                                    editorState: this.state.editor_state,
                                    price: "",
                                    accept_data_saved: false
                                }}
                                validationSchema={
                                    Yup.object().shape({
                                        in_date: Yup.date()
                                            .transform(FormatManager.parseDateString)
                                            .typeError("Veuillez saisir une date")
                                            .required("Date d'entrée requise"),
                                        out_date: Yup.date("Veuillez saisir une date")
                                            .transform(FormatManager.parseDateString)
                                            .typeError("Veuillez saisir une date")
                                            .required("Date de sortie requise"),
                                        menage: Yup.boolean(),
                                        nb_adults: Yup.number("Veuillez saisir un nombre entier positif")
                                            .integer("Veuillez saisir un nombre entier positif")
                                            .min(0, "Veuillez saisir un nombre entier positif")
                                            .required("Nombre d'adultes requis"),
                                        nb_childs: Yup.number("Veuillez saisir un nombre entier positif")
                                            .integer("Veuillez saisir un nombre entier positif")
                                            .min(0, "Veuillez saisir un nombre entier positif")
                                            .required("Nombre d'enfants requis"),
                                        name: Yup.string()
                                            .required("Nom requis"),
                                        firstname: Yup.string()
                                            .required("Prénom requis"),
                                        email: Yup.string()
                                            .email("Veuillez saisir une adresse mail")
                                            .required("Adresse mail requise"),
                                        phone: Yup.lazy((value) => {
                                            if (value) {
                                                return Yup.string()
                                                    .phone(null, false, "Veuillez saisir un numéro de téléphone")
                                                    .notRequired()
                                                    .default(undefined);
                                            } else {
                                                return Yup.mixed().notRequired();
                                            }
                                        }),
                                        address: Yup.string()
                                            .required("Adresse requise"),
                                        postal_code: Yup.string()
                                            .required("Code postal requis"),
                                        city: Yup.string()
                                            .required("Ville requise"),
                                        country: Yup.string()
                                            .required("Pays requis"),
                                        editorState: Yup.object(),
                                        price: Yup.number("Veuillez saisir un montant")
                                            .transform((value, originalValue) => Number(String(originalValue).replace(/,/, ".")))
                                            .typeError("Veuillez saisir un montant")
                                            .positive("Veuillez saisir un montant positif"),
                                        accept_data_saved: Yup.boolean().oneOf([true], "Vous devez accepter que vos données soient enregistrées pour faire votre demande via ce formulaire, sinon, veuillez nous contacter par téléphone ou par mail")
                                    })
                                }
                                innerRef={this.bookingForm}
                                onSubmit={this.onSubmit}
                            >
                                {({ errors, touched, values, setFieldValue, handleChange }) => (
                                    <Form className="create-form" autoComplete="off">
                                        {
                                            this.state.error_response
                                            && <div className="form-error">{this.state.error_response}</div>
                                        }
                                        <div className="form-row">
                                            <div className="form-group">
                                                <label>Date d'entrée*</label>
                                                <div className={touched.in_date && errors.in_date ? "field error" : "field"}>
                                                   <DatePickerField
                                                        name="in_date"
                                                        value={in_date}
                                                        dayPickerProps={{ 
                                                            selectedDays: [in_date, { from: in_date, to: out_date }],
                                                            disabledDays: this.state.inavailable_dates ? this.state.inavailable_dates.concat({ before: tomorrow, after: out_date }) : [],
                                                            month: tomorrow,
                                                            fromMonth: tomorrow,
                                                            toMonth: out_date,
                                                            modifiers: modifiers,
                                                            onDayClick: (day, modifier) => { if (day && ! modifier.disabled) this.outDateRef.current.getInput().focus(); },
                                                            className: "dates-range"
                                                        }}
                                                        onDayChange={this.handleStartChange}
                                                    />
                                                </div>
                                                <ErrorMessage component="div" className="error" name="in_date" />
                                            </div>
                                            <div className="form-group">
                                                <label>Date de sortie*</label>
                                                <div className={touched.out_date && errors.out_date ? "field error" : "field"}>
                                                    <DatePickerField
                                                        name="out_date"
                                                        ref={this.outDateRef}
                                                        value={out_date}
                                                        dayPickerProps={{ 
                                                            selectedDays: [in_date, { from: in_date, to: out_date }],
                                                            disabledDays: this.state.inavailable_dates ? this.state.inavailable_dates.concat({ before: in_date || tomorrow }) : [],
                                                            modifiers: modifiers,
                                                            month: in_date || tomorrow,
                                                            fromMonth: in_date || tomorrow,
                                                            className: "dates-range"
                                                        }}
                                                        onDayChange={this.handleEndChange}
                                                    />
                                                </div>
                                                <ErrorMessage component="div" className="error" name="out_date" />
                                            </div>
                                            <div className="form-group checkbox">
                                                <div className={touched.menage && errors.menage ? "field checkbox error" : "field checkbox"}>
                                                    <label>
                                                        <span className="checkbox-input">
                                                            <span className="checkbox-label nowrap">
                                                                Option ménage {this.state.forfait_menage ? "(" + FormatManager.priceFormat(this.state.forfait_menage.forfait_menage) + ")" : ""}
                                                            </span>
                                                            <Field type="checkbox" name="menage" onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleMenageChange(e.target.checked, setFieldValue);
                                                            }}/>
                                                            <span className="checkbox-control"><Icon fontSize="tiny">checked</Icon></span>
                                                        </span>
                                                    </label>
                                                </div>
                                                <ErrorMessage component="div" className="error" name="menage" />
                                            </div>
                                            <div className="stay-horizontal">
                                                <div className="form-group small">
                                                    <label>Adultes*</label>
                                                    <div className={touched.nb_adults && errors.nb_adults ? "field error" : "field"}>
                                                        <Field name="nb_adults" type="number" min="0" />
                                                    </div>
                                                    <ErrorMessage component="div" className="error" name="nb_adults" />
                                                </div>
                                                <div className="form-group small">
                                                    <label>Enfants*</label>
                                                    <div className={touched.nb_childs && errors.nb_childs ? "field error" : "field"}>
                                                        <Field name="nb_childs" type="number" min="0" />
                                                    </div>
                                                    <ErrorMessage component="div" className="error" name="nb_childs" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group">
                                                <label>Nom*</label>
                                                <div className={touched.name && errors.name ? "field error" : "field"}>
                                                    <Field name="name" autoComplete="family-name" />
                                                </div>
                                                <ErrorMessage component="div" className="error" name="name" />
                                            </div>
                                            <div className="form-group">
                                                <label>Prénom*</label>
                                                <div className={touched.firstname && errors.firstname ? "field error" : "field"}>
                                                    <Field name="firstname" autoComplete="given-name" />
                                                </div>
                                                <ErrorMessage component="div" className="error" name="firstname" />
                                            </div>
                                            <div className="form-group">
                                                <label>Mail*</label>
                                                <div className={touched.email && errors.email ? "field error" : "field"}>
                                                    <Field name="email" autoComplete="email" />
                                                </div>
                                                <ErrorMessage component="div" className="error" name="email" />
                                            </div>
                                            <div className="form-group">
                                                <label>Téléphone*</label>
                                                <div className={touched.phone && errors.phone ? "field error" : "field"}>
                                                    <PhoneInputField name="phone" autoComplete="tel" />
                                                </div>
                                                <ErrorMessage component="div" className="error" name="phone" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group">
                                                <label>Adresse*</label>
                                                <div className={touched.address && errors.address ? "field error" : "field"}>
                                                    <Field name="address" autoComplete="street-address" />
                                                </div>
                                                <ErrorMessage component="div" className="error" name="address" />
                                            </div>
                                            <div className="form-group medium">
                                                <label>Code postal*</label>
                                                <div className={touched.postal_code && errors.postal_code ? "field error" : "field"}>
                                                    <Field name="postal_code" autoComplete="postal-code" />
                                                </div>
                                                <ErrorMessage component="div" className="error" name="postal_code" />
                                            </div>
                                            <div className="form-group medium">
                                                <label>Ville*</label>
                                                <div className={touched.city && errors.city ? "field error" : "field"}>
                                                    <Field name="city" />
                                                </div>
                                                <ErrorMessage component="div" className="error" name="city" />
                                            </div>
                                            <div className="form-group medium">
                                                <label>Pays*</label>
                                                <div className={touched.country && errors.country ? "field error" : "field"}>
                                                    <Field name="country" autoComplete="country-name" />
                                                </div>
                                                <ErrorMessage component="div" className="error" name="country" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group">
                                                <label>Message</label>
                                                <div className={touched.editorState && errors.editorState ? "field editor error" : "field editor"}>
                                                    <EditorField
                                                        editorState={values.editorState}
                                                        disabledBlock={['all']}
                                                        disabledInline={['link']}
                                                        onChange={setFieldValue}
                                                    />
                                                </div>
                                                <ErrorMessage component="div" className="error" name="editorState" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                        <div className="form-group checkbox">
                                                <div className={touched.accept_data_saved && errors.accept_data_saved ? "field checkbox accept-data error" : "field checkbox accept-data"}>
                                                    <label>
                                                        <span className="checkbox-input">
                                                            <Field type="checkbox" name="accept_data_saved" />
                                                            <span className="checkbox-control"><Icon fontSize="tiny">checked</Icon></span>
                                                            <span className="checkbox-label">
                                                                En soumettant ce formulaire, vous acceptez que les données saisies soient enregistrées afin de donner suite à votre demande, mettre en place le contrat de location et nous permettre de vous contacter si nécessaire. Ces informations resteront privées. Seuls votre mail et votre numéro de téléphone seront transmis à la personne en charge de vous accueillir afin de préparer votre arrivée. 
                                                            </span>
                                                        </span>
                                                    </label>
                                                </div>
                                                <ErrorMessage component="div" className="error" name="accept_data_saved" />
                                            </div>
                                        </div>
                                        <Field name="price" type="hidden" />
                                        <div className="buttons-container">
                                            {
                                                values.price
                                                && <div className="pricing">
                                                    <strong>{FormatManager.priceFormat(values.price)}</strong> environ<br/>
                                                    {
                                                        values.menage
                                                        &&<i>(dont {FormatManager.priceFormat(this.state.forfait_menage.forfait_menage)} de forfait ménage)</i>
                                                    }
                                                </div>
                                            }
                                            <button type="submit">
                                                <Icon fontSize="medium">night_shelter</Icon><span>Envoyer votre demande</span>
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}

export default withRouter(Booking);